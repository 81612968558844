import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, ConfirmModal, DropDown, EditModal, Input, RangeCalendar, Title } from "@src/components";
import EssentialInput from "@src/components/input/EssentialInput";
import {
    useGetCommodityCode,
    useGetConnectedProducts,
    useTryCreateProduct,
    useTryUpdateProduct,
    useGetSaleCount,
    useGetCheckCommodityCode,
} from "@src/hooks/queries/useCommodity";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, convertFromHTML, ContentState } from "draft-js";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CommodityDataType } from "./CommodityCreateContents";
import RelatedProduct from "./RelatedProduct";

import draftjsToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    isEventDropDown,
    includeProductTermDropDown,
    purchaseRestrictionsDropDown,
    recommendDropDown,
    renewTermDropDown,
    saleTypeDropDown,
    serveDurationTypeDropDown,
    subsCategoryDropDown,
    subsDropDown,
    typeDropDown,
    productIssueTermDropDown,
    monthlyInstallmentPlanEventDropDown,
} from "@src/constants/Commodity";
import { el, is } from "date-fns/locale";
import { ConvertDate, dateParse } from "@src/utils/dateUtil";
import { ProductType } from "./provision/ProvisionForm";
import { useGetProducts } from "@src/hooks/queries/useCommodityProvision";
import { REGION_LIST, STATE_LAND_REGION_TYPE } from "./components/regionInfo";
import RegionSelectBox from "./components/RegionSelectBox";

export interface ConnectedProductType {
    no: number;
    code: string;
    name: string;
    priceDiscountDisplay1: string;
    priceDiscountDisplay2: string;
    description: string;
    createdAt: string;
    updatedAt: string;
}

interface CommodityUpdateProps {}

const CommodityUpdate = () => {
    const initialState = {
        no: null,
        type: "", // 종류. [subscription:구독, deungi_ticket:등기열람권]
        code: "", // 상품코드. [영문숫자 임의 생성]
        name: "", // 상품명. 구독이름과 함께 앱에 노출됨. eg. 월구독
        subsGroup: "", // 구독종류. [subs_basic_01:일반구독]
        subsGroupName: "", // 구독이름. 상품명과 함께 앱에 노출됨. eg. 정기구독
        renewTerm: "", // 갱신주기. [monthly, yearly, none]
        description: "", // 상품설명.
        nameColor: "", // 상품명 색상.
        nameColorBg: "", // 상품명 배경색상.
        duplRestrict: null, // 구매제한. [0:무제한, 1:CI, 2:user_no, 3:phone, 4:email]
        saleType: null, // 판매형태. [0:미노출(특수경로진입), 1:일반노출, 2:제휴, 3:직원]
        serveDuration: undefined, // 제공기간. 숫자
        serveDurationType: "", // 제공기간단위. [day, month, year]
        serveQuantity: null, // 제공개수. 구독일 경우 1
        price: null, // 가격(정가) - 부가세 포함가격.
        priceIncludedVat: null, // 가격(정가)에 포함된 부가세.
        priceDiscountDisplay1: "", // 할인표시 정보 1. 현재 표시되는곳 없음.
        priceDiscountDisplay2: "", // 할인표시 정보 2. 문자열 그대로 표시됨. eg. -71,500원, 무료체험, 등등
        term1Duration: null, // 할인구간1 - 기간(적용횟수).
        term1DiscountedPrice: null, // 할인구간1 - 가격(할인가) - 부가세 포함가격.
        term1IncludedVat: null, // 할인구간1 - 가격(할인가)에 포함된 부가세.
        term2Duration: null, // 할인구간2 - 기간(적용횟수).
        term2DiscountedPrice: null, // 할인구간2 - 가격(할인가) - 부가세 포함가격.
        term2IncludedVat: null, // 할인구간2 - 가격(할인가)에 포함된 부가세.
        firstPayDelayDay: null, // 지연결제일 (예, 3일무료).
        includeProductCode: "", // 연관상품 - 상품코드.
        includeProductIssueTerm: "", // 연관상품 - 지급주기. [once:한번만지급, month:매월, year:매녕]
        status: 200, // 판매상태. [200:정상판매중, 100:판매중지]
        comments: "",
        displayDescription: "",
        displayOrder: null,
        recommend: 0,
        isEvent: false, // 이벤트 여부
        showStartAt: null, // 노출 기간 시작일
        showEndAt: null, // 노출 기간 종료일
        subsCategory: 0, // 구독 카테고리 0: 일반, 1: 베이직, 2: 스탠다드, 3: 프리미엄
        includeProductList: [], // [연관 상품 json] { productNo: term, … }
        extraData: null,
    };

    const navigate = useNavigate();

    const productInitialState: ProductType = {
        no: 0,
        type: "",
        code: "",
        name: "",
        saleType: 0,
        price: 0,
        discountedPrice: 0,
        subs_group: "",
        subs_group_name: "",
        price_discount_display1: "",
        price_discount_display2: "",
        description: "",
        created_at: "",
        updated_at: "",
    };

    const [staticData, setStaticData] = useState<string>("");
    const [commodityData, setCommodityData] = useState<CommodityDataType>(initialState);

    let initalListPrice: string = "";
    if (commodityData.price && commodityData.priceIncludedVat) {
        initalListPrice = String(commodityData.price - commodityData.priceIncludedVat);
    }
    const [listPrice, setListPrice] = useState<string>(initalListPrice);
    const [discountPrice, setDiscountPrice] = useState<string>();
    const [secondDiscountPrice, setSecondDiscountPrice] = useState<string>();
    const [modalTitle, setModalTitle] = useState("");
    const [productList, setProductList] = useState<ProductType[]>([productInitialState]);
    const { id } = useParams();
    const [selectedItem, setSelectedItem] = useState<any>({
        no: 0,
        code: "",
        name: "",
        price_discount_display1: "",
        price_discount_display2: "",
        description: "",
        created_at: "",
        updated_at: "",
    });
    const [isEnterAll, setIsEnterAll] = useState<boolean>(false);
    const [kindValue, setKindValue] = useState();
    const [isDisable, setIsDisable] = useState<boolean>(true);
    const [flag, setFlag] = useState<boolean>(false);
    const [isCheckModalShow, setIsCheckModalShow] = useState<boolean>(false);
    const [renewTermIsDisabled, setRenewTermIsDisabled] = useState<boolean>(false);
    const [isDuplicateCheck, setIsDuplicateCheck] = useState<boolean>(true);
    const [clickedCheckBtn, setClickedCheckBtn] = useState<boolean>(false);

    // 국공유지 지역 설정 상태 관리
    const [isRegionVisible, setIsRegionVisible] = useState<boolean>(false);
    const [selectRegion, setSelectRegion] = useState<string[]>([]);

    const tryGetCommodityData = async () => {
        if (id) {
            const response = await useGetCommodityCode(id);
            console.log("response?.data", response?.data);

            setCommodityData(response?.data);
            setStaticData(JSON.stringify(response?.data));
            setFlag(true);
        }
    };

    useEffect(() => {
        tryGetCommodityData();
    }, []);

    const {
        type,
        code,
        name,
        subsGroup,
        subsGroupName,
        renewTerm,
        description,
        nameColor,
        nameColorBg,
        duplRestrict,
        saleType,
        serveDuration,
        serveDurationType,
        serveQuantity,
        price,
        priceIncludedVat,
        priceDiscountDisplay1,
        priceDiscountDisplay2,
        term1Duration,
        term1IncludedVat,
        term1DiscountedPrice,
        term2DiscountedPrice,
        term2Duration,
        term2IncludedVat,
        firstPayDelayDay,
        status,
        displayOrder,
        displayDescription,
        isEvent,
        showStartAt,
        showEndAt,
        subsCategory,
        recommend,
        includeProductList,

        // 기존 상품 호환을 위해 추가
        includeProductCode,
        includeProductIssueTerm,

        // 상품 추가 정보
        extraData,
    } = commodityData;

    useEffect(() => {
        if (
            type &&
            status &&
            code &&
            name &&
            priceDiscountDisplay1 &&
            priceDiscountDisplay2 &&
            nameColor &&
            nameColorBg &&
            saleType !== null &&
            saleType !== undefined &&
            duplRestrict !== null &&
            duplRestrict !== undefined &&
            serveDuration &&
            serveDuration >= 1 &&
            serveDurationType &&
            serveQuantity &&
            serveQuantity >= 1 &&
            price !== null &&
            price >= 0 &&
            priceIncludedVat !== null &&
            priceIncludedVat >= 0 &&
            term1Duration !== null &&
            term1Duration >= 0 &&
            term1DiscountedPrice !== null &&
            term1DiscountedPrice >= 0 &&
            term1IncludedVat !== null &&
            term1IncludedVat >= 0 &&
            isDuplicateCheck &&
            ((type === "subscription" && subsGroup && renewTerm && subsGroupName) ||
                type === "deungi_ticket" ||
                type === "corp_deungi_ticket" ||
                type === "campus_registration" ||
                type === "exist_deungi_ticket" ||
                type === "items_ads_ticket" ||
                type === "region_ads_ticket" ||
                type === "premium_filter" ||
                type === "Retail_profit_rate" ||
                // 국공유지 지역 설정 체크
                (type === "state_land_filter" && selectRegion.length > 0) ||
                (type === "road_plan" && selectRegion.length > 0))
        ) {
            setIsEnterAll(true);
        } else {
            setIsEnterAll(false);
        }
    }, [commodityData, isDuplicateCheck]);

    const { data: saleCount } = useGetSaleCount(id);

    // 판매지급현황 1이상이면 수정제한
    useEffect(() => {
        if (saleCount?.data > 0) {
            setIsDisable(true);
        } else {
            setIsDisable(false);
        }
    }, [saleCount]);

    // 판매지급이 0일때, 무료쿠폰인 경우
    useEffect(() => {
        if (saleCount?.data > 0) {
            setRenewTermIsDisabled(true);
        } else if (saleType === 4) {
            setRenewTermIsDisabled(true);
            setDiscountPrice("0");
            setCommodityData({
                ...commodityData,
                term1IncludedVat: 0,
                term1DiscountedPrice: 0,
            });
        } else {
            setRenewTermIsDisabled(false);
        }
    }, [saleType, saleCount]);

    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
    const [htmlString, setHtmlString] = useState<string>("");
    const regTypeEngNum = /^[a-z|A-Z|0-9|]+$/;

    const handleCheckDuplicate = async () => {
        const response = await useGetCheckCommodityCode(commodityData?.code);
        if (commodityData.code === "") {
            alert("상품코드를 입력해주세요.");
            return;
        }
        setIsDuplicateCheck(!response.data);
        !response.data ? alert("사용가능한 상품코드입니다.") : alert("이미 존재하는 상품코드입니다.");
    };

    useEffect(() => {
        if (!editorState.getCurrentContent().hasText()) {
            const blocksFromHtml = htmlToDraft(displayDescription);
            if (blocksFromHtml) {
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);
            }
        }
    }, [displayDescription]);

    const rgbToHex = (r: any, g: any, b: any) => {
        return (
            "#" +
            [r, g, b]
                .map((x) => {
                    const hex = Number(x).toString(16);
                    return hex.length === 1 ? "0" + hex : hex;
                })
                .join("")
        );
    };
    const updateEditorState = (state: any) => {
        setEditorState(state);
        const newData: any = convertToRaw(editorState.getCurrentContent());
        const newBlocks = convertToRaw(editorState.getCurrentContent()).blocks.map((item) => {
            const newInline = item.inlineStyleRanges.map((data: any) => {
                if (data.style.includes("color-rgb") && !data.style.includes("bgcolor-rgb")) {
                    const rgb = data.style.slice(7);
                    const rgbArray: any = rgb.match(/\d+/g);
                    const hex: any = rgbArray ? rgbToHex(rgbArray[0], rgbArray[1], rgbArray[2]) : data.style;

                    return { ...data, style: "color-" + hex };
                } else if (data.style.includes("bgcolor-rgb")) {
                    const rgb = data.style.slice(7);
                    const rgbArray: any = rgb.match(/\d+/g);
                    const hex: any = rgbArray ? rgbToHex(rgbArray[0], rgbArray[1], rgbArray[2]) : data.style;

                    return { ...data, style: "bgcolor-" + hex };
                } else {
                    return { ...data };
                }
            });
            if (newInline) {
                return { ...item, inlineStyleRanges: newInline };
            } else {
                return { ...item };
            }
        });
        newData.blocks = newBlocks;
        const html = draftjsToHtml(newData);

        if (
            convertToRaw(editorState.getCurrentContent()).blocks.length === 1 &&
            convertToRaw(editorState.getCurrentContent()).blocks[0].text === ""
        ) {
            setCommodityData({
                ...commodityData,
                displayDescription: "",
            });
            return;
        }
        setCommodityData({
            ...commodityData,
            displayDescription: html,
        });
        0;
    };

    // const trySetPrice = (
    //     value: string | undefined,
    //     name1: string,
    //     name2: string,
    // ) => {
    //     const numValue = Number(value);
    //     const vat = numValue === 0 ? 0 : parseInt(String(numValue * 0.1));
    //     setCommodityData({
    //         ...commodityData,
    //         [name1]: vat,
    //         [name2]: numValue + vat,
    //     });
    // };

    const trySetPrice = (value: string | undefined, name1: string, name2: string) => {
        const numValue = Number(value);
        if (isNaN(numValue)) {
            return;
        }
        const vat = parseInt(String(numValue * (1 / 11)));
        setCommodityData({
            ...commodityData,
            [name1]: vat,
            [name2]: numValue,
        });
    };

    useEffect(() => {
        if (
            kindValue === "deungi_ticket" ||
            kindValue === "corp_deungi_ticket" ||
            kindValue === "campus_registration" ||
            kindValue === "exist_deungi_ticket" ||
            kindValue === "items_ads_ticket" ||
            kindValue === "region_ads_ticket" ||
            kindValue === "state_land_filter" ||
            kindValue === "road_plan" ||
            kindValue === "premium_filter" ||
            kindValue === "Retail_profit_rate"
        ) {
            setCommodityData({
                ...commodityData,
                type: kindValue,
                subsGroupName: "",
                renewTerm: "",
                subsGroup: "",
                subsCategory: 0,
            });
        } else {
            if (kindValue || kindValue === "") {
                setCommodityData({
                    ...commodityData,
                    type: kindValue,
                });
            }
        }
    }, [kindValue]);

    useEffect(() => {
        if (flag) {
            price && priceIncludedVat && setListPrice(String(price));
            term1DiscountedPrice && term1IncludedVat && setDiscountPrice(String(term1DiscountedPrice));
            term2DiscountedPrice && term2IncludedVat && setSecondDiscountPrice(String(term2DiscountedPrice));
        }
    }, [flag]);

    const handleSubmit = () => {
        if (isEnterAll) {
            useTryUpdateProduct(commodityData, id).then((res) => {
                navigate("/commodity/list", { state: true });
            });
        } else {
            alert("수정실패");
        }
    };

    const handleGetConnectProducts = async (title: string) => {
        // const response = await useGetConnectedProducts();
        // setConnectedProductList(response.data.data.list);

        const response = await useGetProducts();
        setProductList(response.data);
        setModalTitle(title);
    };

    const dateOrTimeStamp = (dateAny: any) => {
        if (typeof dateAny === "number") {
            return dateAny;
        }

        return dateAny ? dateParse(dateAny, "yyyy-MM-dd HH:mm:ss") : null;
    };

    // 국공유지 지역 관련 핸들러
    const onRegionRemoveHandler = (region: string) => {
        const regionArr = selectRegion.slice();
        regionArr.splice(regionArr.indexOf(region), 1);
        setSelectRegion(regionArr);
    };

    // 국공유지 지역 선택시 지역 정보 입력
    useEffect(() => {
        const pnusList = Object.values(REGION_LIST)
            .filter((el) => selectRegion.includes(el.name))
            .reduce((acc: number[], cur) => acc.concat(cur.value || 0), []);

        if (!pnusList.includes(STATE_LAND_REGION_TYPE.ALL)) {
            if (pnusList && pnusList.length > 0) {
                setCommodityData({
                    ...commodityData,
                    extraData: {
                        regions: pnusList,
                    },
                });
            }
        } else {
            setCommodityData({
                ...commodityData,
                extraData: null,
            });
        }
    }, [selectRegion]);

    // 국공유지 지역 초기 설정
    useEffect(() => {
        // 국공유지 상품의 지역 데이터 설정
        if (type === "state_land_filter") {
            const regionList = REGION_LIST.filter(
                (el) => extraData?.regions.filter((pnu: number) => el.value?.includes(pnu)).length > 0,
            ).map((el2) => el2.name);
            if (regionList.length > 0) setSelectRegion(regionList);
            else setSelectRegion([REGION_LIST[0].name]);

            console.log("re", regionList);
        } else if (type === "road_plan") {
            const regionList = REGION_LIST.filter(
                (el) => extraData?.regions.filter((pnu: number) => el.value?.includes(pnu)).length > 0,
            ).map((el2) => el2.name);
            if (regionList.length > 0) setSelectRegion(regionList);
            else setSelectRegion([REGION_LIST[0].name]);

            console.log("re", regionList);
        }
    }, [type]);

    return (
        <>
            <StyleSalesCount>
                <div>
                    <span>
                        <span className="essential">*</span>판매/지급 현황
                    </span>
                    <span style={{ marginLeft: "20px" }}>{saleCount?.data}</span>
                </div>
                <p style={{ marginLeft: "6px" }}>※판매/지급 현황이 1이상인 경우 일부 정부 수정이 제한됩니다.</p>
            </StyleSalesCount>
            <div css={detailStyle}>
                <Title title="상품 수정" />
                <StyledCreateContents>
                    <Title title="상품정보" size="small" />
                    <StyledSaleType>
                        <text>
                            <span>*</span>판매여부
                        </text>
                        <label htmlFor="saleRadio">
                            <input
                                type="radio"
                                id="saleRadio"
                                name="sale"
                                checked={status === 200}
                                onChange={(e) => {
                                    setCommodityData({
                                        ...commodityData,
                                        status: 200,
                                    });
                                }}
                            />
                            <span>판매중</span>
                        </label>
                        <label htmlFor="saleStopRadio">
                            <input
                                type="radio"
                                name="sale"
                                id="saleStopRadio"
                                checked={status === 100}
                                onChange={(e) => {
                                    setCommodityData({
                                        ...commodityData,
                                        status: 100,
                                    });
                                }}
                            />
                            <span>판매중지</span>
                        </label>
                    </StyledSaleType>
                    <StyledKind>
                        <text>
                            <span>*</span>종류
                        </text>
                        <div className="dropDownDiv">
                            <DropDown
                                data={typeDropDown}
                                defaultValue={type ?? ""}
                                placeholder="항목을 선택해주세요"
                                border={false}
                                handleChange={(e: any) => {
                                    setKindValue(e.target.value);
                                }}
                                isDisabled={isDisable}
                            />
                        </div>
                    </StyledKind>
                    <StyledSaleType style={{ marginBottom: 0 }}>
                        <text>
                            <span>*</span>노출기간
                        </text>
                        <label htmlFor="noShowRadio">
                            <input
                                type="radio"
                                id="noShowRadio"
                                name="show"
                                checked={showStartAt === null}
                                onChange={(e) => {
                                    setCommodityData({
                                        ...commodityData,
                                        showStartAt: null,
                                        showEndAt: null,
                                    });
                                }}
                            />
                            <span>미설정</span>
                        </label>
                        <label htmlFor="showRadio">
                            <input
                                type="radio"
                                name="show"
                                id="showRadio"
                                checked={!!showStartAt}
                                onChange={(e) => {
                                    setCommodityData({
                                        ...commodityData,
                                        showStartAt: new Date(),
                                        showEndAt: new Date(),
                                    });
                                }}
                            />
                            <span>설정</span>
                        </label>
                    </StyledSaleType>
                    {showStartAt !== null && (
                        <RangeCalendar
                            setRangeDate={{
                                startDate: showStartAt !== null ? new Date(showStartAt).getTime() : null,
                                endDate: showEndAt !== null ? new Date(showEndAt).getTime() : null,
                            }}
                            getUserRangeDate={({ startDate, endDate }) => {
                                setCommodityData({
                                    ...commodityData,
                                    showStartAt: startDate !== null && !isNaN(startDate) ? new Date(startDate) : null,
                                    showEndAt: endDate !== null && !isNaN(endDate) ? new Date(endDate) : null,
                                });
                            }}
                            timePicker={true}
                        />
                    )}
                    {type === "state_land_filter" && (
                        <StyledItemLow style={{ marginTop: "25px" }}>
                            <StyledButtonInput>
                                <span>
                                    <span className="essential">*</span>
                                    지역선택
                                </span>
                                <div className="regionBtns">
                                    {selectRegion &&
                                        selectRegion.map((el, idx) => {
                                            return (
                                                <label key={idx}>
                                                    {el}
                                                    <button onClick={() => onRegionRemoveHandler(el)}>X</button>
                                                </label>
                                            );
                                        })}
                                    {selectRegion.length === 0 && <span>지역을 선택해주세요</span>}
                                </div>
                                <Button
                                    color="gd"
                                    handleClick={() => {
                                        setIsRegionVisible(true);
                                    }}
                                    label="선택"
                                    size="small"
                                />
                            </StyledButtonInput>
                            <RegionSelectBox
                                visible={isRegionVisible}
                                setVisible={setIsRegionVisible}
                                selectRegion={selectRegion}
                                setSelectRegion={setSelectRegion}
                            />
                        </StyledItemLow>
                    )}
                    {type === "road_plan" && (
                        <StyledItemLow style={{ marginTop: "25px" }}>
                            <StyledButtonInput>
                                <span>
                                    <span className="essential">*</span>
                                    지역선택
                                </span>
                                <div className="regionBtns">
                                    {selectRegion &&
                                        selectRegion.map((el, idx) => {
                                            return (
                                                <label key={idx}>
                                                    {el}
                                                    <button onClick={() => onRegionRemoveHandler(el)}>X</button>
                                                </label>
                                            );
                                        })}
                                    {selectRegion.length === 0 && <span>지역을 선택해주세요</span>}
                                </div>
                                <Button
                                    color="gd"
                                    handleClick={() => {
                                        setIsRegionVisible(true);
                                    }}
                                    label="선택"
                                    size="small"
                                />
                            </StyledButtonInput>
                            <RegionSelectBox
                                visible={isRegionVisible}
                                setVisible={setIsRegionVisible}
                                selectRegion={selectRegion}
                                setSelectRegion={setSelectRegion}
                            />
                        </StyledItemLow>
                    )}
                    <StyledDropDown style={{ marginTop: "20px" }}>
                        <span>
                            <span className="essential">*</span>판매형태
                        </span>
                        <div className="dropDownDiv">
                            <DropDown
                                data={saleTypeDropDown}
                                defaultValue={String(saleType ?? "")}
                                placeholder="항목을 선택해주세요"
                                handleChange={(e, text, value) => {
                                    setCommodityData({
                                        ...commodityData,
                                        saleType: Number(value),
                                    });
                                }}
                                label=""
                                isDisabled={isDisable}
                            />
                        </div>
                    </StyledDropDown>
                    <StyledCommodityCreateContents>
                        <StyledItemLow>
                            <StyledButtonInput>
                                <span>
                                    <span className="essential">*</span>
                                    상품코드
                                </span>
                                {/* <div>{code}</div> */}
                                <input
                                    type="text"
                                    value={commodityData.code}
                                    placeholder="영문, 숫자만 사용하여 입력해주세요"
                                    onChange={(e) => {
                                        setCommodityData({
                                            ...commodityData,
                                            code: e.target.value,
                                        });
                                        setIsDuplicateCheck(false);
                                        // setCodeCheck(false);
                                    }}
                                    disabled={isDisable}
                                />
                                <Button
                                    color="gd"
                                    handleClick={() => {
                                        if (regTypeEngNum.test(code)) {
                                            code.length > 0 && handleCheckDuplicate();
                                            setClickedCheckBtn(!clickedCheckBtn);
                                        } else {
                                            alert("영어, 숫자만 입력해주세요");
                                        }
                                    }}
                                    label="중복검사"
                                    size="small"
                                    isDisabled={
                                        staticData.length > 0 && commodityData.code === JSON.parse(staticData)?.code
                                    }
                                />
                            </StyledButtonInput>
                            <EssentialInput
                                label="상품명"
                                essential={true}
                                placeholder="이름을 입력해주세요"
                                data={commodityData}
                                value={name}
                                name={"name"}
                                onChange={setCommodityData}
                            />
                        </StyledItemLow>
                        {type === "subscription" && (
                            <StyledItemLow>
                                <StyledDropDown>
                                    <span>
                                        <span className="essential">*</span>
                                        구독종류
                                    </span>
                                    <div className="dropDownDivSubscription">
                                        <DropDown
                                            data={subsDropDown}
                                            defaultValue={subsGroup}
                                            placeholder="항목을 선택해주세요"
                                            handleChange={(e, text, value) => {
                                                setCommodityData({
                                                    ...commodityData,
                                                    subsGroup: value,
                                                });
                                            }}
                                            label=""
                                            isDisabled={isDisable}
                                        />
                                    </div>
                                </StyledDropDown>
                                <StyledDropDown>
                                    <span>
                                        <span className="essential">*</span>
                                        갱신주기
                                    </span>
                                    <div className="dropDownDivSubscription">
                                        <DropDown
                                            data={renewTermDropDown}
                                            placeholder={saleType === 4 ? "없음" : ""}
                                            handleChange={(e, text, value) => {
                                                setCommodityData({
                                                    ...commodityData,
                                                    renewTerm: value,
                                                });
                                            }}
                                            defaultValue={saleType === 4 ? "없음" : renewTerm ?? ""}
                                            label=""
                                            isDisabled={renewTermIsDisabled}
                                        />
                                    </div>
                                </StyledDropDown>
                            </StyledItemLow>
                        )}
                        <StyledItemLow>
                            {type === "subscription" && (
                                <>
                                    <StyledDropDown>
                                        <span>
                                            <span className="essential">*</span>
                                            분류 카테고리
                                        </span>
                                        <div className="dropDownDiv">
                                            <DropDown
                                                data={subsCategoryDropDown}
                                                defaultValue={subsCategory.toString()}
                                                handleChange={(e, text, value) => {
                                                    console.log("value", value);
                                                    setCommodityData({
                                                        ...commodityData,
                                                        subsCategory: Number(value),
                                                        subsGroupName:
                                                            Number(value) > 0
                                                                ? subsCategoryDropDown[Number(value)].value
                                                                : subsGroupName,
                                                    });
                                                }}
                                                label=""
                                            />
                                        </div>
                                    </StyledDropDown>
                                    <EssentialInput
                                        label="구독이름"
                                        data={commodityData}
                                        name={"subsGroupName"}
                                        onChange={setCommodityData}
                                        essential={true}
                                        value={subsGroupName}
                                        placeholder="이름입력"
                                        style={{ width: 320 }}
                                        isDisabled={subsCategory > 0}
                                    />
                                </>
                            )}
                        </StyledItemLow>
                        <StyledItemLow>
                            <EditorDiv>
                                <span>
                                    <span className="essential">*</span>상품설명
                                    {<br />}
                                    (노출용)
                                </span>
                                <Editor
                                    placeholder="상품을 등록해주세요"
                                    editorState={editorState}
                                    onEditorStateChange={updateEditorState}
                                    localization={{ locale: "ko" }}
                                    editorStyle={{
                                        height: "400px",
                                        width: "100%",
                                        border: "3px solid lightgray",
                                        padding: "20px",
                                    }}
                                />
                            </EditorDiv>
                        </StyledItemLow>
                        <StyledItemLow>
                            <StyledReasonWrapper>
                                <span>상품설명{<br />}(관리용)</span>
                                <input
                                    type="text"
                                    placeholder="상품설명을 입력해주세요"
                                    value={description}
                                    onChange={(e) => {
                                        setCommodityData({
                                            ...commodityData,
                                            description: e.target.value,
                                        });
                                    }}
                                />
                            </StyledReasonWrapper>
                        </StyledItemLow>
                        <StyledItemLow>
                            <EssentialInput
                                label="상품 노출순서"
                                essential={false}
                                name={"displayOrder"}
                                onChange={setCommodityData}
                                value={displayOrder ?? ""}
                                data={commodityData}
                                placeholder="1~1000이하 숫자로 입력해주세요"
                                number={true}
                            />
                            <StyledDropDown>
                                <span>추천 여부</span>
                                <DropDown
                                    data={recommendDropDown}
                                    defaultValue={String(recommend ?? "")}
                                    handleChange={(e, text, value) => {
                                        setCommodityData({
                                            ...commodityData,
                                            recommend: Number(value),
                                        });
                                    }}
                                    label=""
                                />
                            </StyledDropDown>
                        </StyledItemLow>
                        <StyledItemLow>
                            <EssentialInput
                                label="상품문구"
                                data={commodityData}
                                essential={true}
                                value={priceDiscountDisplay1}
                                name={"priceDiscountDisplay1"}
                                onChange={setCommodityData}
                                placeholder="상품문구를 입력해주세요"
                            />
                            <EssentialInput
                                label="할인금액"
                                essential={true}
                                name={"priceDiscountDisplay2"}
                                onChange={setCommodityData}
                                value={priceDiscountDisplay2}
                                data={commodityData}
                                placeholder="할인금액을 입력해주세요(숫자, 문자열 모두 가능)"
                            />
                        </StyledItemLow>
                        <StyledItemLow>
                            <EssentialInput
                                label="상품명 색상"
                                essential={true}
                                data={commodityData}
                                value={nameColor}
                                name={"nameColor"}
                                onChange={setCommodityData}
                                color={true}
                                placeholder="색상코드를 입력해주세요(예 #FFFFFF)"
                                style={{ paddingLeft: 35 }}
                            />

                            <EssentialInput
                                label={"상품명 배경 색상"}
                                value={nameColorBg}
                                essential={true}
                                data={commodityData}
                                name={"nameColorBg"}
                                onChange={setCommodityData}
                                color={true}
                                placeholder="색상코드를 입력해주세요(예 #FFFFFF)"
                                style={{ paddingLeft: 35 }}
                            />
                        </StyledItemLow>
                        <StyledItemLow>
                            <StyledDropDown>
                                <span>
                                    <span className="essential">*</span>구매제한
                                </span>
                                <div className="dropDownDiv">
                                    <DropDown
                                        data={purchaseRestrictionsDropDown}
                                        defaultValue={String(duplRestrict ?? "")}
                                        placeholder="항목을 선택해주세요"
                                        handleChange={(e, text, value) => {
                                            setCommodityData({
                                                ...commodityData,
                                                duplRestrict: Number(value),
                                            });
                                        }}
                                        label=""
                                    />
                                </div>
                            </StyledDropDown>
                            <StyledDropDown>
                                <span>
                                    <span className="essential">*</span>이벤트 여부
                                </span>
                                <div className="dropDownDiv">
                                    <DropDown
                                        data={isEventDropDown}
                                        defaultValue={Number(isEvent).toString() ?? ""}
                                        handleChange={(e, text, value) => {
                                            setCommodityData({
                                                ...commodityData,
                                                isEvent: Boolean(Number(value)),
                                            });
                                        }}
                                        label=""
                                    />
                                </div>
                            </StyledDropDown>
                        </StyledItemLow>
                        <StyledItemLow>
                            <div className="provisionPeriod">
                                <EssentialInput
                                    label="제공기간"
                                    value={serveDuration ?? ""}
                                    essential={true}
                                    name={"serveDuration"}
                                    onChange={setCommodityData}
                                    data={commodityData}
                                    placeholder="숫자를 입력해주세요"
                                    style={{ width: 200 }}
                                    isDisabled={isDisable}
                                />
                                <div className="dropDownDiv">
                                    <DropDown
                                        data={serveDurationTypeDropDown}
                                        defaultValue={serveDurationType ?? ""}
                                        placeholder="기간단위"
                                        handleChange={(e, text, value) => {
                                            setCommodityData({
                                                ...commodityData,
                                                serveDurationType: value,
                                            });
                                        }}
                                        label=""
                                    />
                                </div>
                            </div>
                            <EssentialInput
                                label="제공개수"
                                value={serveQuantity ?? ""}
                                data={commodityData}
                                name={"serveQuantity"}
                                onChange={setCommodityData}
                                essential={true}
                                placeholder="숫자를 입력해주세요"
                            />
                        </StyledItemLow>
                        {(type === "subscription" || kindValue === "subscription") && (
                            <StyledItemLow>
                                <StyledEventWrapper>
                                    <StyledDropDown>
                                        <span>
                                            <span className="essential">*</span>이벤트 할부 개월
                                        </span>
                                        <DropDown
                                            data={monthlyInstallmentPlanEventDropDown}
                                            defaultValue={commodityData.extraData?.installment ?? "할부없음"}
                                            handleChange={(e, text, value) => {
                                                setCommodityData({
                                                    ...commodityData,
                                                    extraData: {
                                                        ...commodityData.extraData,
                                                        installment: Number(value),
                                                    },
                                                });
                                            }}
                                        />
                                    </StyledDropDown>
                                    <div className="description">할부개월 선택에 따라 이벤트 기간 상품 동일 지급</div>
                                </StyledEventWrapper>
                                <StyledEventWrapper>
                                    <div className="flex">
                                        <span>
                                            <span className="essential">*</span>이벤트 할부 개월 미해당
                                        </span>
                                        <div
                                            className="productAddBtn"
                                            onClick={() => {
                                                handleGetConnectProducts("상품 조회");
                                            }}
                                        >
                                            상품 조회
                                        </div>
                                        <span>{commodityData.extraData?.originProductCode ?? ""}</span>
                                    </div>
                                    <div className="description">이벤트 할부개월이 아닐 시 검색된 상품으로 지급</div>
                                </StyledEventWrapper>
                            </StyledItemLow>
                        )}
                        <StyledItemLow>
                            <StyledPriceWrapper>
                                <span>
                                    <span className="essential">*</span>
                                    가격(정가)
                                    {<br />}※부가세 포함
                                </span>

                                <input
                                    type="text"
                                    value={Number(listPrice ?? "").toLocaleString()}
                                    placeholder="숫자를 입력해주세요"
                                    onChange={(e) => {
                                        const newVal = e.target.value.replaceAll(",", "");
                                        if (isNaN(Number(newVal))) {
                                            return;
                                        }
                                        setListPrice(newVal);
                                    }}
                                    onBlur={(e) => {
                                        trySetPrice(listPrice, "priceIncludedVat", "price");
                                    }}
                                />
                            </StyledPriceWrapper>
                            <StyledPriceWrapper>
                                <span>부가세</span>
                                <StyledPrice>{Number(priceIncludedVat)?.toLocaleString()}</StyledPrice>
                            </StyledPriceWrapper>
                            <StyledPriceWrapper>
                                <span>
                                    가격(정가)
                                    {<br />}※부가세 미포함
                                </span>
                                <StyledPrice>
                                    {(Number(listPrice) - Number(priceIncludedVat))?.toLocaleString()}
                                </StyledPrice>
                            </StyledPriceWrapper>
                        </StyledItemLow>
                        <Title title="할인정보" size="small" />
                        <StyledItemLow>
                            할인구간1
                            <EssentialInput
                                label="기간(적용횟수)"
                                data={commodityData}
                                value={term1Duration ?? ""}
                                name={"term1Duration"}
                                onChange={setCommodityData}
                                essential={true}
                                placeholder="숫자를 입력해주세요"
                                style={{ width: 180 }}
                            />
                        </StyledItemLow>
                        <StyledItemLow>
                            <StyledPriceWrapper>
                                <span>
                                    <span className="essential">*</span>
                                    가격(할인가)
                                    {<br />}※부가세 포함
                                </span>
                                <input
                                    type="text"
                                    value={
                                        saleType === 4
                                            ? "0"
                                            : discountPrice
                                            ? Number(discountPrice).toLocaleString()
                                            : "0"
                                    }
                                    onChange={(e) => {
                                        const newVal = e.target.value.replaceAll(",", "");
                                        if (isNaN(Number(newVal))) {
                                            return;
                                        }
                                        setDiscountPrice(newVal);
                                    }}
                                    onBlur={() => {
                                        trySetPrice(
                                            kindValue === "subscription" && saleType === 4 ? "0" : discountPrice,
                                            "term1IncludedVat",
                                            "term1DiscountedPrice",
                                        );
                                    }}
                                />
                            </StyledPriceWrapper>
                            <StyledPriceWrapper>
                                <span>부가세</span>
                                <StyledPrice>{Number(term1IncludedVat)?.toLocaleString()}</StyledPrice>
                            </StyledPriceWrapper>
                            <StyledPriceWrapper>
                                <span>
                                    가격(할인가)
                                    {<br />}※부가세 미포함
                                </span>
                                <StyledPrice>
                                    {(Number(discountPrice ?? 0) - Number(term1IncludedVat ?? 0))?.toLocaleString()}
                                </StyledPrice>
                            </StyledPriceWrapper>
                        </StyledItemLow>
                        <StyledItemLow>
                            할인구간2
                            <EssentialInput
                                label="기간(적용횟수)"
                                value={term2Duration ?? ""}
                                data={commodityData}
                                essential={false}
                                name={"term2Duration"}
                                onChange={setCommodityData}
                                placeholder="숫자를 입력해주세요"
                                style={{ width: 180 }}
                            />
                        </StyledItemLow>
                        <StyledItemLow>
                            <StyledPriceWrapper>
                                <span>
                                    가격(할인가)
                                    {<br />}※부가세 포함
                                </span>
                                <input
                                    type="text"
                                    value={secondDiscountPrice ? Number(secondDiscountPrice).toLocaleString() : ""}
                                    onChange={(e) => {
                                        const newVal = e.target.value.replaceAll(",", "");
                                        if (isNaN(Number(newVal))) {
                                            return;
                                        }
                                        setSecondDiscountPrice(newVal);
                                    }}
                                    onBlur={() => {
                                        trySetPrice(secondDiscountPrice, "term2IncludedVat", "term2DiscountedPrice");
                                    }}
                                />
                            </StyledPriceWrapper>
                            <StyledPriceWrapper>
                                <span>부가세</span>
                                <StyledPrice>{Number(term2IncludedVat)?.toLocaleString()}</StyledPrice>
                            </StyledPriceWrapper>
                            <StyledPriceWrapper>
                                <span>
                                    가격(할인가)
                                    {<br />}※부가세 미포함
                                </span>
                                <StyledPrice>
                                    {(
                                        Number(secondDiscountPrice ?? 0) - Number(term2IncludedVat ?? 0)
                                    )?.toLocaleString()}
                                </StyledPrice>
                            </StyledPriceWrapper>
                        </StyledItemLow>
                        <StyledItemLow>첫결제 지연일</StyledItemLow>
                        <StyledItemLow>
                            <EssentialInput
                                label="지연일수"
                                data={commodityData}
                                value={firstPayDelayDay ?? ""}
                                name={"firstPayDelayDay"}
                                onChange={setCommodityData}
                                essential={false}
                                placeholder="숫자를 입력해주세요"
                                style={{ width: 180 }}
                            />
                            일
                        </StyledItemLow>
                        <>
                            <Title title="연관상품" size="small" />

                            <StyledItemLow
                                style={{
                                    background: "rgba(0,0,0,0.2)",
                                    opacity: 0.3,
                                    width: "800px",
                                }}
                            >
                                <StyledButtonInput>
                                    상품코드
                                    <div>{includeProductCode}</div>
                                </StyledButtonInput>
                                <StyledDropDown>
                                    <span>지급주기</span>
                                    <div className="dropDownDiv">{includeProductIssueTerm ?? ""}</div>
                                </StyledDropDown>
                            </StyledItemLow>
                            <StyledItemLow>
                                <IncludeProductListBox>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>번호</th>
                                                <th>상품명</th>
                                                <th>상품코드</th>
                                                <th>지급주기</th>
                                                <th>삭제</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {includeProductList &&
                                                Object.keys(includeProductList).length > 0 &&
                                                Object.keys(includeProductList).map((el, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <td>{includeProductList[idx].no}</td>
                                                            <td>{includeProductList[idx].name}</td>
                                                            <td>{includeProductList[idx].code}</td>
                                                            <td>
                                                                <DropDown
                                                                    data={
                                                                        type === "subscription"
                                                                            ? includeProductTermDropDown
                                                                            : [includeProductTermDropDown[0]]
                                                                    }
                                                                    defaultValue={includeProductList[el].term}
                                                                    handleChange={(e, text, value) => {
                                                                        includeProductList[idx].term = value;

                                                                        setCommodityData((prev) => {
                                                                            return {
                                                                                ...prev,
                                                                                includeProductList,
                                                                            };
                                                                        });
                                                                    }}
                                                                    label=""
                                                                />
                                                            </td>
                                                            <td>
                                                                <label
                                                                    onClick={() => {
                                                                        includeProductList.splice(idx, 1);

                                                                        setCommodityData((prev) => {
                                                                            return {
                                                                                ...prev,
                                                                                includeProductList: includeProductList,
                                                                            };
                                                                        });
                                                                    }}
                                                                >
                                                                    삭제
                                                                </label>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            <tr>
                                                <td colSpan={5}>
                                                    <div className={"productAddBtn"}>
                                                        <span
                                                            onClick={() => {
                                                                handleGetConnectProducts("연관 상품 조회");
                                                            }}
                                                        >
                                                            상품 조회
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </IncludeProductListBox>
                            </StyledItemLow>
                        </>
                    </StyledCommodityCreateContents>

                    <Button
                        color="gd"
                        handleClick={() => {
                            setIsCheckModalShow(true);
                        }}
                        label="수정"
                        size="medium"
                        styles={{
                            position: "absolute",
                            bottom: 30,
                            right: 500,
                        }}
                        isDisabled={
                            !(staticData.length > 0 && staticData !== JSON.stringify(commodityData) && isEnterAll)
                        }
                    />

                    <EditModal
                        buttonText="OK"
                        handleBtnClick={() => {
                            if (modalTitle === "연관 상품 조회") {
                                includeProductList.push({
                                    no: selectedItem.no,
                                    name: selectedItem.name,
                                    code: selectedItem.code,
                                    term: "once",
                                });
                                setCommodityData((prev) => {
                                    return {
                                        ...prev,
                                        includeProductList: includeProductList
                                            .filter(
                                                (el1: any, idx: number) =>
                                                    includeProductList.findIndex((el2: any) => el1.no === el2.no) ===
                                                    idx,
                                            )
                                            .sort((el1: any, el2: any) => el1.no - el2.no),
                                    };
                                });
                                return;
                            }

                            if (modalTitle === "상품 조회") {
                                setCommodityData((prev) => {
                                    return {
                                        ...prev,
                                        extraData: {
                                            ...prev.extraData,
                                            originProductCode: selectedItem.code,
                                        },
                                    };
                                });
                                return;
                            }
                        }}
                        handleOpenModal={() => {
                            setModalTitle("");
                        }}
                        size="big"
                        title={modalTitle}
                        isModalShow={!!modalTitle}
                        isDisabled={!selectedItem.code}
                    >
                        <div>
                            <RelatedProduct
                                ProductList={productList}
                                setSelectedItem={setSelectedItem}
                                selectedItem={selectedItem}
                            />
                        </div>
                    </EditModal>
                    <ConfirmModal
                        buttonText="확인"
                        handleCancel={() => {
                            setIsCheckModalShow(false);
                        }}
                        handleOk={() => {
                            handleSubmit();
                            setIsCheckModalShow(false);
                        }}
                        isModalShow={isCheckModalShow}
                    >
                        수정 하시겠습니까?
                    </ConfirmModal>
                </StyledCreateContents>
            </div>
        </>
    );
};

export default CommodityUpdate;

const IncludeProductListBox = styled.div`
    position: relative;
    width: 100%;

    table {
        width: 800px;

        & > thead {
            & > tr {
                line-height: 2.2;
                border-bottom: 1px solid #999;
                text-align: left;

                & > th:nth-of-type(1),
                & > th:nth-of-type(3),
                & > th:nth-of-type(4),
                & > th:nth-of-type(5) {
                    width: 100px;
                    text-align: center;
                }
            }
        }
        & > tbody {
            & > tr {
                line-height: 3;
                text-align: left;

                & > td:nth-of-type(1),
                & > td:nth-of-type(3),
                & > td:nth-of-type(4),
                & > td:nth-of-type(5) {
                    text-align: center;
                }
                & > td > label {
                    border: 1px solid #9a49f2;
                    border-radius: 8px;
                    font-size: 12px;
                    color: #9a49f2;
                    padding: 4px;
                    cursor: pointer;
                }

                .productAddBtn {
                    width: 100%;
                    text-align: center;
                    line-height: 3;
                    background: #eee;

                    & > span {
                        border: 1px solid #9a49f2;
                        border-radius: 8px;
                        font-size: 12px;
                        color: #9a49f2;
                        padding: 4px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
`;

const detailStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    position: relative;
    input {
        background: none;
    }
`;
const StyledCommodityCreateContents = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-top: 30px;
    padding-bottom: 40px;
`;

const StyledItemLow = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 48px;
    font-weight: 400;
    font-size: 15px;
    color: #222222;

    & > .provisionPeriod {
        display: flex;
        gap: 24px;
        align-items: center;
    }
    & > .dropDown {
        & > div {
            & > label {
                & > select {
                    font-weight: 400;
                    font-size: 14px;
                    width: 96px;
                }
            }
        }
    }
`;

const StyledInputWrapper = styled.div`
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    & > span {
        font-weight: 400;
        font-size: 14px;
        color: #f45b5b;
    }
    & > input {
        margin-left: 16px;
        border: none;
        box-sizing: border-box;
        border-bottom: 1px solid #e3e3e6;
        border-radius: 0px;
        padding: 13px;
        outline: none;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
        width: 328px;
        height: 42px;
    }
    & > input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #9d9fa5;
    }
`;

const StyledReasonWrapper = styled.div`
    bottom: 20px;
    width: 100%;
    height: 48px;
    display: flex;
    gap: 16px;
    align-items: center;
    & > span {
        font-weight: 400;
        font-size: 13px;
        width: 80px;
        line-height: 18px;

        .essential {
            color: #f45b5b;
        }
    }
    & > input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #9d9fa5;
    }
    & > input {
        height: 100%;
        width: calc(100% - 96px);
        padding: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: #283237;
        border: 1px solid #e3e3e6;
        border-radius: 8px;
        outline: none;
    }
`;

const StyledPriceWrapper = styled.div`
    bottom: 20px;
    width: fit-content;
    height: 48px;
    display: flex;
    gap: 16px;
    align-items: center;
    & > span {
        font-weight: 400;
        font-size: 13px;
        width: fit-content;
        line-height: 18px;

        .essential {
            color: #f45b5b;
        }
    }
    & > input {
        margin-left: 16px;
        border: none;
        box-sizing: border-box;
        border-bottom: 1px solid #e3e3e6;
        border-radius: 0px;
        padding: 13px;
        outline: none;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
        width: 180px;
        height: 42px;
    }
    & > input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #9d9fa5;
    }
`;

const StyledDropDown = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    & > span {
        font-weight: 400;
        font-size: 14px;

        line-height: 18px;
        color: #222222;
        .essential {
            color: #f45b5b;
        }
    }

    & > .dropDownDivSubscription {
        & > div {
            & > label {
                & > select {
                    color: #222222;
                    font-weight: 400;
                    font-size: 14px;
                    border: none;
                    border-bottom: 1px solid #b4b4b9;
                    border-radius: 0;
                    width: 180px;
                }
            }
        }
    }

    & > .dropDownDiv {
        & > div {
            & > label {
                & > select {
                    color: #222222;
                    font-weight: 400;
                    font-size: 14px;
                    border: none;
                    border-bottom: 1px solid #b4b4b9;
                    border-radius: 0;
                    width: 328px;
                }
            }
        }
    }
`;

const StyledPrice = styled.div`
    margin-left: 16px;
    border: none;
    box-sizing: border-box;
    border-bottom: 1px solid #e3e3e6;
    border-radius: 0px;
    padding: 13px;
    outline: none;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    width: 180px;
    height: 42px;
`;

const StyledButtonInput = styled.div`
    display: flex;
    width: fit-content;
    align-items: center;
    position: relative;

    & > span {
        font-weight: 400;
        font-size: 14px;

        line-height: 18px;
        color: #222222;
        .essential {
            color: #f45b5b;
        }
    }

    & > div,
    input {
        margin-left: 16px;
        border: none;
        box-sizing: border-box;
        border-bottom: 1px solid #e3e3e6;
        border-radius: 0px;
        padding: 13px;
        outline: none;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
        width: 328px;
        height: 42px;

        &.regionBtns {
            user-select: none;

            & > label {
                margin-right: 10px;
                border: 1px solid #ccc;
                border-radius: 3px;
                padding: 2px 5px;

                & > button {
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
            & > span {
                color: #999;
            }
        }
    }
    & > input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #9d9fa5;
    }
    & > button {
        position: absolute;
        right: 10px;
    }
`;

const StyledCreateContents = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 54px;
    width: 100%;
`;

const StyledSaleType = styled.div`
    display: flex;
    margin-top: 25px;
    margin-bottom: 35px;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #222222;

    & > text {
        width: 70px;
        & > span {
            color: #f45b5b;
        }
    }
    & > label {
        display: flex;
        margin-left: 54px;
        gap: 4px;
        align-items: center;
        & > input {
            outline: none;
            color: black;
            border: 1px solid black !important;
            border-radius: 100px;
        }
    }
`;

const StyledKind = styled.div`
    display: flex;
    margin-top: 17px;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    & > text {
        width: 60px;
        & > span {
            color: #f45b5b;
        }
    }
    & > .dropDownDiv {
        /* color: black; */
        & > div {
            & > label {
                & > select {
                    color: black;
                    /* border-bottom: 1px solid #b4b4b9; */
                    border-radius: 0;
                    width: 300px;
                }
            }
        }
        margin-left: 54px;
    }
`;

const EditorDiv = styled.div`
    width: 100%;

    display: flex;
    gap: 16px;
    align-items: flex-start;
    & > span {
        font-weight: 400;
        font-size: 13px;
        width: 80px;
        line-height: 18px;

        .essential {
            color: #f45b5b;
        }
    }
`;

const StyleSalesCount = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 160px;
    left: 60%;
    gap: 5px;
    align-items: flex-start;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    .box {
        display: flex;
        flex-direction: row;
        & > span {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #222222;
            .essential {
                color: #f45b5b;
            }
        }
    }
    .essential {
        color: #f45b5b;
    }
    & > p {
        color: #f45b5b;
        font-weight: 400;
        font-size: 10px;
    }

    & > .dropDownDivSubscription {
        & > div {
            & > label {
                & > select {
                    color: #222222;
                    font-weight: 400;
                    font-size: 14px;
                    border: none;
                    border-bottom: 1px solid #b4b4b9;
                    border-radius: 0;
                    width: 180px;
                }
            }
        }
    }

    & > .dropDownDiv {
        & > div {
            & > label {
                & > select {
                    color: #222222;
                    font-weight: 400;
                    font-size: 14px;
                    border: none;
                    border-bottom: 1px solid #b4b4b9;
                    border-radius: 0;
                    width: 328px;
                }
            }
        }
    }
`;

const StyledEventWrapper = styled.div`
    & .flex {
        display: flex;
        gap: 16px;
        align-items: center;
        span {
            font-weight: 400;
            font-size: 14px;

            line-height: 18px;
            color: #222222;
            .essential {
                color: #f45b5b;
            }
        }

        & .productAddBtn {
            border: 1px solid #9a49f2;
            border-radius: 8px;
            color: #9a49f2;
            padding: 4px 18px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            line-height: 170%;
        }
    }

    & .description {
        margin-left: 8px;

        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #f45b5b;
    }
`;
