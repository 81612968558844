import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import PushForm from "./PushForm";
import { PushNowRequestBodyType, PushRequestBodyType, PushType } from "../types";
import { Button } from "@src/components";
import { ConvertDate } from "@src/utils/dateUtil";
import { isNotEmpty } from "@src/utils/textUtil";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";
import { useNavigate } from "react-router-dom";
import { useCreatePushData, useCreatePushNowData } from "@src/hooks/queries/usePushDetail";
import { usePushList } from "@src/hooks/queries/usePush";
import { Common } from "@src/styles/Common";
import { useQueryClient } from "react-query";
import { queryKeys } from "@src/hooks/queries/queryKeys";

export default function PushCreate() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initData = {
        category: "0",
        userTypes: [],
        subscriptionTypes: [],
        receivers: [],
        testers: [],
        reservationAt: ConvertDate(new Date().setHours(0, 0, 0), "yyyy.MM.dd HH:mm:ss"),
        link: "",
        title: "",
        contents: "",
        pushStatus: "",
        edit: false,
        pushNo: 0,
        status: 100,
        listNo: 1,
        originReservationAt: "",
        sendNow: true,
    };
    const [updateData, setUpdateData] = useState<PushType>();
    const [disabled, setDisabled] = useState(false);
    const [pageErrorMessage, setPageErrorMessage] = useState<any>("");
    const changeValidation = ({
        userTypes = [],
        subscriptionTypes = [],
        receivers = [],
        testers = [],
        link,
        title,
        contents,
        edit,
    }: any) => {
        if (
            (userTypes.length || subscriptionTypes.length || receivers.length) &&
            isNotEmpty(title) &&
            isNotEmpty(contents)
        ) {
            // 버튼 활성화
            return edit ? false : true;
        } else {
            return true;
        }
    };

    useEffect(() => {
        const isChange = changeValidation({ ...updateData });
        setDisabled(isChange);
        setPageErrorMessage("");
    }, [updateData]);

    const { data: pushData, refetch: listRefetch } = usePushList({ limit: 10, page: 1 });
    const { mutate: createMutate } = useCreatePushData();
    const queryClient = useQueryClient();
    const { mutate: createNowMutate } = useCreatePushNowData();
    const handleSubmit = () => {
        if (updateData) {
            const {
                receivers = [""],
                testers = [""],
                category,
                title = "",
                contents = "",
                reservationAt,
                link = "",
                userTypes = [""],
                subscriptionTypes = [""],
                sendNow = true,
            } = updateData;

            const to_type = userTypes.length || subscriptionTypes.length ? "topic" : "user_no";
            const params: PushRequestBodyType = {
                to_type,
                to_go: receivers.toString(),
                to_tester: testers.toString(),
                category: Number(category),
                msg_title: title,
                msg_body: contents,
                reservation_at: reservationAt,
                msg_click_action: link,
                user_types: userTypes,
                subscription_types: subscriptionTypes,
            };
            const nowParams: PushNowRequestBodyType = {
                toType: to_type,
                category: Number(category),
                msgTitle: title,
                msgBody: contents,
                msgClickAction: link,
                userTypes: userTypes,
                subscriptionTypes: subscriptionTypes,
                toGo: receivers,
            };
            dispatch(startLoading());
            if (sendNow) {
                createNowMutate(nowParams, {
                    onSuccess: () => {
                        navigate("/notify/push");
                        queryClient.invalidateQueries(queryKeys.PUSH_LIST);
                    },
                    onError: (error: any) => {
                        setPageErrorMessage(error);
                    },
                    onSettled: () => {
                        dispatch(endLoading());
                    },
                });
            } else {
                createMutate(params, {
                    onSuccess: () => {
                        navigate("/notify/push");
                        queryClient.invalidateQueries(queryKeys.PUSH_LIST);
                    },
                    onError: (error: any) => {
                        setPageErrorMessage(error);
                    },
                    onSettled: () => {
                        dispatch(endLoading());
                    },
                });
            }
        }
    };

    return (
        <div css={createDiv}>
            <PushForm
                title={"PUSH 전송 등록"}
                initData={initData}
                handleUpdate={setUpdateData}
                isUpdate={false}
                isDetail={false}
                isDisabledCheckbox={true}
            />
            <div className="buttonBox">
                <p>{pageErrorMessage}</p>
                <Button
                    label={"등록"}
                    color={"gd"}
                    size="medium"
                    isDisabled={disabled}
                    handleClick={handleSubmit}
                    deleteBtn={false}
                    defaultValue={""}
                />
            </div>
        </div>
    );
}

const createDiv = css`
    padding-right: 40px;
    padding-bottom: 40px;
    width: 100%;
    .buttonBox {
        width: 100%;
        padding-top: 28px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;
        p {
            color: ${Common.colors.error};
        }
    }
`;
