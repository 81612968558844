import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { DropDown, Title } from "@src/components";
import { typeDropDown } from "@src/constants/Commodity";
import React, { useState } from "react";
import CommodityCreateContents from "./CommodityCreateContents";

const CommodityCreate = () => {
    const [kindValue, setKindValue] = useState();
    const [saleStatus, setSaleStatus] = useState<number>(200);

    return (
        <>
            <div css={detailStyle}>
                <Title title="상품 등록" />
                <StyledCreateContents>
                    <Title title="상품정보" size="small" />
                    <StyledSaleType>
                        <text>
                            <span>*</span>판매여부
                        </text>
                        <label htmlFor="saleRadio">
                            <input
                                type="radio"
                                id="saleRadio"
                                name="sale"
                                checked={saleStatus === 200}
                                onChange={() => {
                                    setSaleStatus(200);
                                }}
                            />
                            <span>판매중</span>
                        </label>
                        <label htmlFor="saleStopRadio">
                            <input
                                type="radio"
                                name="sale"
                                id="saleStopRadio"
                                checked={saleStatus === 100}
                                onChange={() => {
                                    setSaleStatus(100);
                                }}
                            />
                            <span>판매중지</span>
                        </label>
                    </StyledSaleType>
                    <StyledKind>
                        <text>
                            <span>*</span>종류
                        </text>
                        <div className="dropDownDiv">
                            <DropDown
                                data={typeDropDown}
                                defaultValue=""
                                placeholder="항목을 선택해주세요"
                                border={false}
                                handleChange={(e: any) => {
                                    setKindValue(e.target.value);
                                }}
                            />
                        </div>
                    </StyledKind>
                    {!(
                        kindValue === "deungi_ticket" ||
                        kindValue === "corp_deungi_ticket" ||
                        kindValue === "subscription" ||
                        kindValue === "campus_registration" ||
                        kindValue === "exist_deungi_ticket" ||
                        kindValue === "items_ads_ticket" ||
                        kindValue === "region_ads_ticket" ||
                        kindValue === "state_land_filter" ||
                        kindValue === "road_plan" ||
                        kindValue === "premium_filter" ||
                        kindValue === "Retail_profit_rate"
                    ) ? (
                        <StyledCreateWrapper>종류를 선택해주세요</StyledCreateWrapper>
                    ) : (
                        <CommodityCreateContents kindValue={kindValue} saleStatus={saleStatus} />
                    )}
                </StyledCreateContents>
            </div>
        </>
    );
};

export default CommodityCreate;

const detailStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    position: relative;
`;

const StyledCreateContents = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 54px;
    width: 100%;
`;

const StyledSaleType = styled.div`
    display: flex;
    margin-top: 25px;
    margin-bottom: 35px;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #222222;

    & > text {
        width: 70px;
        & > span {
            color: #f45b5b;
        }
    }
    & > label {
        display: flex;
        margin-left: 54px;
        gap: 4px;
        align-items: center;
        & > input {
            outline: none;
            color: black;
            border: 1px solid black !important;
            border-radius: 100px;
        }
    }
`;

const StyledKind = styled.div`
    display: flex;
    margin-top: 17px;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    & > text {
        width: 60px;
        & > span {
            color: #f45b5b;
        }
    }
    & > .dropDownDiv {
        /* color: black; */
        & > div {
            & > label {
                & > select {
                    color: black;
                    /* border-bottom: 1px solid #b4b4b9; */
                    border-radius: 0;
                    width: 300px;
                }
            }
        }
        margin-left: 54px;
    }
`;

const StyledCreateWrapper = styled.div`
    width: 100%;
    background-color: #f2f2f2;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 20px;
    color: #6c6c6c;
    margin-top: 10px;
`;
