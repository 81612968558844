import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import AdminForm from "./AdminForm";
import { Common } from "@src/styles/Common";
import { useParams } from "react-router-dom";
import { useAdminDetail } from "@src/hooks/queries/useAdminDetail";
import { useLoginDetail } from "@src/hooks/queries/useLogin";

function AdminDetail() {
    const { id: ADMIN_ID } = useParams();
    const [initAdmin, setInitAdmin] = useState<any>();
    const { isError, isLoading, data: initAdmins } = useAdminDetail(ADMIN_ID ? ADMIN_ID : "0");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const { data: loginDetail } = useLoginDetail();

    useEffect(() => {
        if (!loginDetail) return;
        setIsSuperAdmin(loginDetail.admin_no === "100" || loginDetail.admin_no === "101");
    }, [loginDetail]);

    useEffect(() => {
        if (!isLoading && !isError && initAdmins) {
            setInitAdmin(initAdmins);
        }
    }, [isLoading, isError, initAdmins]);
    return (
        <div css={adminDetailWrap}>
            <AdminForm isUpdate={false} title={"관리자 상세"} initData={initAdmin} isSuperAdmin={isSuperAdmin} />
        </div>
    );
}
const adminDetailWrap = css`
    width: 100%;
    padding: 40px;
    input:disabled {
        background-color: ${Common.colors.white};
        color: ${Common.colors.black};
    }
`;
export default AdminDetail;
