import React, { useState, useEffect, ChangeEvent } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Input, Title, SearchModal, Button } from "@src/components";
import { AdminFormProps } from "@src/types/adminType";
import { useUserNoSearch } from "@src/hooks/queries/useUser";
import { adminPlaceholders } from "@src/constants/AdminText";

interface AdminProps {
    isUpdate: boolean;
    title: string;
    initData: any;
    isSuperAdmin?: boolean;
    handleSubmit?: Function;
    updatePasswordErrorMSG?: string;
}
function AdminForm({
    isUpdate = false,
    title,
    isSuperAdmin,
    initData,
    handleSubmit,
    updatePasswordErrorMSG,
}: AdminProps) {
    const headers = ["이름", "이메일", "연락처", "회원번호"];
    const [isSearchModalShow, setIsSearchModalShow] = useState(false);
    const [updateData, setUpdateData] = useState<any>({
        ...initData,
        updatePassword: isSuperAdmin ? "" : null,
        edit: false,
    });

    useEffect(() => {
        setUpdateData({ ...initData, updatePassword: isSuperAdmin ? "" : null, edit: false });
    }, [initData]);

    const { adminName, adminPhone, adminId, adminRank, userNo, password, edit, updatePassword } = updateData;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUpdateData({
            ...updateData,
            [name]: value,
            edit: true,
        });
    };

    useEffect(() => {
        edit && handleSubmit && handleSubmit(updateData);
    }, [updateData]);

    let isCreated = title.includes("상세") || title.includes("수정");

    const [userNoList, setUserNoList] = useState<any[]>([]);

    const [searchUserNoParams, setSearchUserNoParams] = useState<{
        q: string;
        page: 1;
        limit: 100;
        enabled: boolean;
    }>({
        q: "",
        page: 1,
        limit: 100,
        enabled: false,
    });

    const {
        parseData,
        isLoading: searchLoading,
        refetch: searchRefetch,
        isRefetching: searchRefetching,
    } = useUserNoSearch(searchUserNoParams);

    useEffect(() => {
        if (searchLoading) {
            setSearchUserNoParams((prevState: any) => ({
                ...prevState,
                ...{ enabled: false },
            }));
        } else {
            setUserNoList(parseData);
        }
    }, [searchLoading]);

    useEffect(() => {
        searchRefetching && setUserNoList(parseData);
    }, [searchRefetching]);

    const handleSearch = (value?: string) => {
        value === undefined && (value = "");
        setSearchUserNoParams({ q: value, page: 1, limit: 100, enabled: true });
        searchRefetch();
    };

    return (
        <div>
            <Title title={title} />
            <ul css={adminFormUl}>
                <li>
                    <Input
                        label={"관리자 이름"}
                        placeholder={adminPlaceholders.adminName}
                        type={"text"}
                        size={"default"}
                        handleChange={handleChange}
                        isDisabled={!isUpdate}
                        errorMessage={""}
                        name={"adminName"}
                        value={adminName || ""}
                    />
                </li>
                <li>
                    <Input
                        label={"관리자 연락처"}
                        placeholder={adminPlaceholders.adminPhone}
                        type={"text"}
                        size={"default"}
                        handleChange={handleChange}
                        isDisabled={!isUpdate}
                        errorMessage={""}
                        name={"adminPhone"}
                        value={adminPhone || ""}
                    />
                </li>

                <li>
                    <Input
                        label={"직위"}
                        placeholder={adminPlaceholders.adminRank}
                        type={"text"}
                        size={"default"}
                        handleChange={handleChange}
                        isDisabled={!isUpdate}
                        errorMessage={""}
                        name={"adminRank"}
                        value={adminRank || ""}
                    />
                </li>
                {isSuperAdmin && (
                    <li>
                        <Input
                            label={"비밀번호"}
                            placeholder="********"
                            type="text"
                            size={"default"}
                            handleChange={handleChange}
                            isDisabled={!isUpdate}
                            errorMessage={updatePasswordErrorMSG}
                            name={"updatePassword"}
                            value={isUpdate ? updatePassword || "" : "********"}
                        />
                        {isUpdate && <span css={updatePasswordInfo}>* 영문, 숫자 포함 7글자 이상</span>}
                    </li>
                )}
                {isCreated || (
                    <>
                        <li>
                            <Input
                                label={"아이디"}
                                placeholder={adminPlaceholders.adminId}
                                type={"text"}
                                size={"default"}
                                handleChange={handleChange}
                                isDisabled={!isUpdate}
                                errorMessage={""}
                                name={"adminId"}
                                value={adminId || ""}
                            />
                        </li>
                        <li>
                            <Input
                                label={"비밀번호"}
                                placeholder={adminPlaceholders.password}
                                type={"text"}
                                size={"default"}
                                handleChange={handleChange}
                                isDisabled={!isUpdate}
                                errorMessage={""}
                                name={"password"}
                                value={password || ""}
                            />
                        </li>
                        <li>
                            <span>회원번호</span>
                            <div css={userNoDiv}>
                                <div>{userNo}</div>
                                <Button
                                    color="gray"
                                    label="회원번호 연결"
                                    size="xSmall"
                                    handleClick={() => setIsSearchModalShow(!isSearchModalShow)}
                                />
                                <SearchModal
                                    isModalShow={isSearchModalShow}
                                    title="회원번호"
                                    buttonText="저장"
                                    placeholder={"이름을 입력하세요."}
                                    isLoading={searchLoading}
                                    list={userNoList}
                                    headers={headers}
                                    handleOpenModal={() => setIsSearchModalShow(!isSearchModalShow)}
                                    handleSave={(data: any) => {
                                        setUpdateData({
                                            ...updateData,
                                            userNo: data.userNo,
                                            edit: true,
                                        });
                                        setIsSearchModalShow(false);
                                    }}
                                    handleSearch={handleSearch}
                                    errorMessage={""}
                                />
                            </div>
                        </li>
                    </>
                )}
            </ul>
        </div>
    );
}

const adminFormUl = css`
    margin-top: 40px;
    span {
        display: inline-block;
        width: 120px;
        ${Common.textStyle.title6_R14}
    }
    li {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
`;
const userNoDiv = css`
    display: flex;
    align-items: center;
    & > div {
        margin-right: 10px;
        ${Common.textStyle.title6_R14}
    }
`;
const updatePasswordInfo = css`
    position: absolute;
    top: calc(100% + 8px);
    font-weight: 400;
    font-size: 10px;
    color: #c0c0c0;
    left: 120px;
    white-space: nowrap;
`;
export default AdminForm;
