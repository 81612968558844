export const queryKeys = {
    ADMIN_DATA: "admin",
    EXPERT_DATA: "expertList",
    USAGE_BIG: "usageBig",
    USAGE_SMALL: "usageSmall",
    PRODUCT_LIST: "productList",
    EXPERT_DETAIL_DATA: "expertDetail",
    FAQ_LIST: "faqList",
    FAQ_DETAIL_DATA: "faqDetail",
    NOTICE_DATA: "noticeList",
    NEWS_DATA: "newsList",
    LOGIN_DETAIL: "loginDetail",
    ADMIN_DETAIL: "adminDetail",
    SUPPORT_DATA: "supportList",
    SUPPORT_REPLY_DATA: "supportReply",
    NEWS_DETAIL_DATA: "newsDetail",
    NOTICE_DETAIL_DATA: "noticeDetail",
    LOCATION_BIG: "locationBig",
    LOCATION_SMALL: "locationSmall",
    HOME_COUNT: "homeCount",
    HOME_USER_CHART: "homeUserChart",
    HOME_SALES_CHART: "homeSalesChart",
    HOME_ITEM_SALES_CHART: "homeSalesChart",
    HOME_ITEM_REFUND_CHART: "homeRefundChart",
    HOME_SUBS_OS_CHART: "homeSubsOsChart",
    HOME_PAID_USER_CHART: "homePaidUserChart",
    HOME_NEW_MEMBERS_CHART: "homeNewMembersChart",
    HOME_PAYMENT_STAT_CHART: "homePaymentStatChart",
    PUSH_CATEGORY_LIST: "pushCategoryList",
    PUSH_RECEIVERS: "pushReceivers",
    PUSH_LIST: "pushList",
    PUSH_DETAIL_DATA: "pushDetail",
    PUSH_LOG_LIST: "pushLogList",
    USER_LIST: "userList",
    ADMIN_LIST: "adminList",
    BANNER_LIST: "bannerList",
    BANNER_CLICK_COUNT: "bannerClickCount",
    FILTER_BANNER_LIST: "filterBannerList",
    BANNER_DETAIL_DATA: "bannerDetail",
    USER_DETAIL_DATA: "userDetail",
    USER_UPDATE_HISTORY: "userUpdateHistory",
    PUSH_RECEIVING_USER_LIST: "pushReceivingUserList",
    PUSH_RECEIVING_ADMIN_LIST: "pushReceivingAdminList",
    EXPERTS_UPDATE_HISTORY: "expertsUpdateHistory",
    EXPERTS_CONFIRM_HISTORY: "expertsConfirmHistory",
    EXPERTS_CONFIRM_DETAIL: "expertsConfirmDetail",
    EXPERTS_OFFICE_LIST: "expertsOfficeList",
    TEMPLATE_LIST: "templateList",
    TEMPLATE_DETAIL_DATA: "templateDetail",
    TEMPLATE_DUPLICATE: "templateDuplicate",
    SITUATION_LIST: "situationList",
    USER_RECENT_VIEW_HISTORY: "useRecentViewHistory",
    SITUATION_DETAIL_DATA: "situationDetail",
    SKIP_BID_HISTORY: "skipBidHistory",
    SUMMARY_INFORMATION: "summaryInformation",
    MAIN_INFORMATION: "mainInformation",
    DEFAULT_INFORMATION: "defaultInformation",
    CASE_INFORMATION: "caseInformation",
    ITEM_INFORMATION: "itemInformation",
    CASE_ANALYSIS: "caseAnalysis",
    SALES_STATUS: "salesStatus",
    RIGHTS_ANALYSIS: "rightsAnalysis",
    DOCUMENT_HISTORY: "documentHistory",
    DELIVERY_HISTORY: "deliveryHistory",
    EXPECTED_RETURN: "expectedReturn",
    COUPON_LIST: "couponList",
    COUPON_DATA: "couponData",
    COUPON_DETAIL_LIST: "couponDetailList",
    COUPON_TYPE: "couponType",
    USER_PAYMENT_HISTORY: "userPaymentHistory",
    COMMODITY_LIST: "commodityList",
    COMMODITY_PROVISION_LIST: "commodityProvisionList",
    PROVISION_USER_LIST: "procisionUserList",
    USER_DEUNGI_LIST: "userDeungiList",
    DEUNGI_LIST: "deungiList",
    DEUNGI_NOTICE_LIST: "deungiNoticeList",
    CAMPUS: "campus",
    PARTNER_LIST: "PartnerList",
    PARTNER_BENEFITS_LIST: "partnerBenefitsList",
    PARTNER_BENEFITS: "partnerBenefits",
    NOTEXPERTUSER: "notExpertUserList",
    EXPERTHOME: "expertHome",
    EXPERTLIST: "expertList",
    EXPERTUSER: "expertUser",
    CHANGEHISTORY: "expertUserChangeHistory",
    CHANGEHISTORYDETAILS: "expertUserChangeHistoryDetails",
    EXPERTINQUIRIES: "expertInquiries",
    SGGLIST: "sggList",
    AGENTLIST: "agnetList",
    OFFICELIST: "officeList",
    SIDOLIST: "sidoList",
    PARTNER_CONNETIONS_LIST: "partenrConnetionsList",
    PARTNER_SUBS_LIST: "partnerSubsList",
    DEUNGI_CHANGE_LIST: "deungiChangeList",
    DEUNGI_CHANGE_LOG_LIST: "deungiChangeLogList",
    LOGGED_IN_ADMIN_DETAIL: "loggedInAdminDetail",
};
