import { asyncCall } from "@src/api/asyncCall";
import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useMutation } from "react-query";

export const useResetPassword = () => {
    const resetPassword = async (email: string) => {
        const promise = await axiosInstance.post(`${axiosPath.resetPassword}`, { email });
        return asyncCall(promise, "Common");
    };

    return useMutation(resetPassword);
};
