import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { AdminDetailProps } from "@src/types/adminType";
import { queryKeys } from "./queryKeys";
import { asyncCall } from "@src/api/asyncCall";

const initialAdminDetail = {
    admin_name: "아이유",
    admin_phone: "010-i-u",
    admin_rank: "프로",
    user_no: null,
    comments: null,
    created_by: 100,
    updated_by: 100,
    created_at: "2022-07-14 01:33:20",
    updated_at: "2022-07-14 01:33:20",
};

const fetchData = async (adminNo: string) => {
    const response = await axiosInstance.get(`${axiosPath.admins}/${adminNo}`);
    const { data } = response.data.data;

    if (!response.status || data.length === 0) {
        throw new Error("Problem fetching data");
    }

    return parse(data[0]);
};
const parse = (originData: any) => {
    const { admin_name, admin_phone, admin_rank, user_no, comments, created_by, updated_by, created_at, updated_at } =
        originData;
    return {
        adminName: admin_name,
        adminPhone: admin_phone,
        adminRank: admin_rank,
        adminId: "",
        userNo: user_no,
        comments,
        createdBy: created_by,
        updatedBy: updated_by,
        createdAt: created_at,
        updatedAt: updated_at,
    };
};
const useAdminDetail = (adminNo: string) => {
    return useQuery<AdminDetailProps, Error>([queryKeys.ADMIN_DETAIL, adminNo], () => fetchData(adminNo), {
        initialData: useQueryClient().getQueryData([queryKeys.ADMIN_DETAIL]),
    });
};

const useUpdateAdmin = (adminNo: number) => {
    const updateUser = async (params: any) => {
        const promise = await axiosInstance.patch(`${axiosPath.admins}/${adminNo}`, params);
        return asyncCall(promise, "Common");
    };

    return useMutation(updateUser);
};

const useCreateAdmin = () => {
    const createAdmin = async (params: any) => {
        const promise = await axiosInstance.post(`${axiosPath.admins}`, params);
        return asyncCall(promise, "User");
    };
    return useMutation(createAdmin);
};

const useDeleteAdmin = () => {
    const deleteAdmin = async (adminNo: number) => {
        const promise = await axiosInstance.delete(`${axiosPath.admins}/${adminNo}`);
        return asyncCall(promise, "User");
    };
    return useMutation(deleteAdmin);
};

const useUpdatePasswordBySuperAdmin = () => {
    const updatePassword = async ({ no, passwdNew }: { no: number; passwdNew: string }) => {
        const promise = await axiosInstance.put(`${axiosPath.updatePasswordBySuperAdmin}`, { no, passwdNew });
        return asyncCall(promise, "User");
    };
    return useMutation(updatePassword);
};

export { useAdminDetail, useUpdateAdmin, useCreateAdmin, useDeleteAdmin, useUpdatePasswordBySuperAdmin };
