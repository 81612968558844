import React, { ChangeEvent, useEffect, useState } from "react";
import Logo from "@src/assets/logo_gd.png";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Input, Button } from "@src/components";
import { Link } from "react-router-dom";
import { useResetPassword } from "@src/hooks/queries/useResetPassword";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@src/store";
import { endLoading, startLoading } from "@src/reducers/loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ResetPasswordPage() {
    const [email, setEmail] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [emailError, setEmailError] = useState("");
    const dispatch = useDispatch<AppDispatch>();
    const { mutate } = useResetPassword();

    const emailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);

        if (value.endsWith("@missgoauction.com")) {
            setEmailError("");
            setButtonDisabled(false);
        } else {
            setEmailError("이메일 형식이 올바르지 않습니다.");
            setButtonDisabled(true);
        }
    };

    const handleClick = () => {
        dispatch(startLoading());
        mutate(email, {
            onSuccess: () => {
                toast.success(
                    <>
                        비밀번호가 변경되었습니다. <br />
                        메일을 확인해주세요.
                    </>,
                );
            },
            onError: () => {
                setEmailError("이메일을 확인해주세요.");
            },
            onSettled: () => {
                dispatch(endLoading());
            },
        });
    };

    useEffect(() => {
        dispatch(endLoading());
    }, []);

    return (
        <div css={loginWrap}>
            <div css={loginDiv}>
                <img src={Logo} alt="미스고" />
                <div css={emptyDivByEmail} />
                <Input
                    value={email}
                    placeholder="이메일"
                    isDisabled={false}
                    handleChange={emailChange}
                    errorMessage={emailError}
                    size="default"
                />
                <Button
                    label="임시 비밀번호 해당 메일로 전송하기"
                    size="big"
                    color="gd"
                    handleClick={handleClick}
                    isDisabled={buttonDisabled}
                />
                <Link to="/login" css={passwordChangeLink}>
                    로그인 하러가기
                </Link>
                <div css={infoDiv}>
                    ※ 로그인 후, 마이페이지에서 비밀번호 변경해 주세요.
                    <br /> 최고 관리자 : info@missgoauction.com
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

const loginWrap = css`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Common.colors.gray100};
`;

const loginDiv = css`
    width: 640px;
    height: 436px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${Common.colors.white};
    border-radius: 16px;

    button {
        margin-top: 28px;
    }
    Input:nth-of-type(2) {
        border-bottom-color: ${Common.colors.gray200};
    }
    Input p {
        padding-top: 8px;
    }
    p:nth-of-type(1) {
        height: 0;
    }
    p:nth-of-type(2) {
        padding-top: 8px;
    }
`;

const emptyDivByEmail = css`
    height: 36px;
`;

const infoDiv = css`
    color: #9d9fa5;
    font-size: 12px;
    width: 309px;
    height: 44px;
    margin: 26px 0;
    text-align: center;
    ${Common.textStyle.body4_R12}
    white-space: nowrap;
`;

const passwordChangeLink = css`
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    color: #93959b;
    margin-top: 12px;
`;
