const basePath = process.env.REACT_APP_API_URL;
const nestPath = process.env.REACT_APP_NEST_API_URL;
const login_v2 = `${basePath}/auth/login`;
const resetPassword = `${basePath}/auth/passwd/reset`;
const updatePasswordBySuperAdmin = `${basePath}/auth/passwd/manager`;
const passwd = `${basePath}/auth/passwd`;
const article = `${basePath}/news/article`;
const article2 = `${basePath}/news/article2`;
const newsImage = `${basePath}/news/image`;
const admin = `${basePath}/admin`;
const board = `${basePath}/board`;
const inquirySingle = `${basePath}/inquiry/chat`;
const chats = `${basePath}/inquiry/chats`;
const users = `${basePath}/users`;
const nestUsers = `${nestPath}/users`;
const banner = `${basePath}/banner`;
const postbox = `${basePath}/postbox`;
const pushNow = `${nestPath}/notifications/push-groups/send/now`;
const admins = `${basePath}/admin/admins`;
const experts = `${basePath}/experts`;
const home = `${basePath}/home/stats`;
const postboxTemplate = `${basePath}/postboxTemplate`;
const postboxSituation = `${basePath}/postboxSituation`;
const itemsAuction = `${basePath}/items/auction`;
const itemsAuctionSummaryInfo = `${itemsAuction}/summaryInfo`;
const itemsAuctionSkipBid = `${itemsAuction}/skipBid`;
const itemsAuctionCaseInfo = `${itemsAuction}/caseInfo`;
const recentviews = `${basePath}/items/recentviews`;
const mainInfo = `${basePath}/items/auction/mainInfo`;
const basicInfo = `${basePath}/items/auction/basicInfo`;
const buildingInfo = `${basePath}/items/buildingInfo`;
const landInfo = `${basePath}/items/landInfo`;
const itemsExpectedReturn = `${basePath}/items/expectReturn`;
const itemsAuctionCaseAnalysis = `${itemsAuction}/caseAnalysis`;
const itemsAuctionSaleItem = `${itemsAuction}/saleItem`;
const coupon = `${basePath}/coupon`;
const product = `${basePath}/product`;
const commodity = `${basePath}/product`;
const freeIssue = `${basePath}/product/free_issue`;
const boardNotices = `${nestPath}/board-notices`;
const deungi = `${nestPath}/deungi`;
const deungiV2 = `${nestPath}/v2/deungi`;
const nestFreeIssue = `${nestPath}/product-free-issues`;
const nestFreeIssueV1 = `${nestPath}/v1/product-free-issues`;
const nestProducts = `${nestPath}/products`;
const saleStatus = `${nestPath}/dashboard/salesStat`;
const homeSummary = `${nestPath}/dashboard/summary`;
const dailyVisitor = `${nestPath}/dashboard/visitorStat`;
const subsOsPercents = `${nestPath}/dashboard/subsOsPercent`;
const paidUser = `${nestPath}/dashboard/newPaidSubs`;
const newMember = `${nestPath}/dashboard/newMembers`;
const refundStatus = `${nestPath}/dashboard/refundStat`;
const paymentStat = `${nestPath}/dashboard/paymentStat`;
const nestCampus = `${nestPath}/campus`;
const nestUpload = `${nestPath}/upload`;
const nestPgRefund = `${nestPath}/payment/pg/refund`;
const nestCoupon = `${nestPath}/coupon`;
const partner = `${nestPath}/user-payback-partners`;
const partnerBenefits = `${nestPath}/payback-partner-benefits`;
const userExpert = `${nestPath}/user-experts`;
const expertInquiry = `${nestPath}/user-expert-info-inquiries`;
const address = `${nestPath}/address`;
const agentList = `${nestPath}/agent-offices`;
const officeList = `${nestPath}/kabl-offices`;
const v1Url = `${nestPath}/v1`;
const nestNotifications = `${nestPath}/notifications`;
const nestNotificationsV1 = `${nestPath}/v1/notifications`;
const subsLeaved = `${nestPath}/dashboard/subsLeaveList`;
const dashboardPath = `${nestPath}/dashboard`;
const ticket = `${nestPath}/ticket`;
const payment = `${nestPath}/payment`;
const stateLand = `${nestPath}/state-land`;
const roadExpansion = `${nestPath}/road-plan`;
const goStop = `${nestPath}/user/gostop`;
const settlementPath = `${nestPath}/user-payback-partners/partnerCalcList`;
const paymentListPath = `${nestPath}/user-payback-partners/partnerPaymentList`;
const bannerList = `${nestPath}/banner`;
const getBannerClickCount = `${nestPath}/banner/stat`;

export const axiosPath = {
    login_v2,
    resetPassword,
    updatePasswordBySuperAdmin,
    passwd,
    article,
    newsImage,
    admin,
    board,
    inquirySingle,
    chats,
    users,
    nestUsers,
    banner,
    postbox,
    pushNow,
    admins,
    experts,
    home,
    postboxTemplate,
    postboxSituation,
    article2,
    itemsAuction,
    itemsAuctionSummaryInfo,
    itemsAuctionSkipBid,
    itemsAuctionCaseInfo,
    recentviews,
    mainInfo,
    basicInfo,
    buildingInfo,
    landInfo,
    itemsAuctionCaseAnalysis,
    itemsExpectedReturn,
    itemsAuctionSaleItem,
    coupon,
    product,
    commodity,
    freeIssue,
    boardNotices,
    deungi,
    nestFreeIssue,
    nestProducts,
    nestFreeIssueV1,
    saleStatus,
    homeSummary,
    dailyVisitor,
    subsOsPercents,
    paidUser,
    newMember,
    refundStatus,
    nestCampus,
    nestUpload,
    nestPgRefund,
    nestCoupon,
    partner,
    partnerBenefits,
    paymentStat,
    nestNotifications,
    nestNotificationsV1,
    userExpert,
    expertInquiry,
    address,
    agentList,
    officeList,
    v1Url,
    deungiV2,
    subsLeaved,
    dashboardPath,
    ticket,
    payment,
    stateLand,
    settlementPath,
    paymentListPath,
    roadExpansion,
    goStop,
    bannerList,
    getBannerClickCount,
};
