import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import AdminForm from "./AdminForm";
import { useParams, useNavigate } from "react-router-dom";
import { Button, ConfirmModal } from "@src/components";
import { useAdminDetail, useUpdateAdmin, useUpdatePasswordBySuperAdmin } from "@src/hooks/queries/useAdminDetail";
import { useAdminList } from "@src/hooks/queries/useAdmin";
import { startLoading, endLoading } from "@src/reducers/loading";
import { useDispatch } from "react-redux";
import { useLoggedInAdminDetail } from "@src/hooks/queries/useLogin";

function AdminUpdate() {
    const { id: ADMIN_ID } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [initAdmin, setInitAdmin] = useState<any>();
    const [updatedAdmin, setUpdatedAdmin] = useState({ edit: false });
    const [isDisabled, setIsDisabled] = useState(true);
    const [isModalShow, setIsModalShow] = useState(false);
    const [pageErrorMessage, setPageErrorMessage] = useState("");
    const [updatePasswordErrorMSG, setUpdatePasswordErrorMSG] = useState("");
    const { data: loggedInAdminDetail } = useLoggedInAdminDetail();
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        if (!loggedInAdminDetail) return;
        setIsSuperAdmin(loggedInAdminDetail.admin_no === 100 || loggedInAdminDetail.admin_no === 101);
    }, [loggedInAdminDetail]);

    useEffect(() => {
        updatedAdmin.edit && setIsDisabled(false);
    }, [updatedAdmin]);

    const { isError, isLoading, data: initAdmins, refetch: getRefetch } = useAdminDetail(ADMIN_ID ? ADMIN_ID : "0");

    useEffect(() => {
        if (!isLoading && !isError && initAdmins) {
            setInitAdmin(initAdmins);
        }
    }, [isLoading, isError, initAdmins]);

    const { refetch: listRefetch } = useAdminList({ page: 1, limit: 10 });
    const { mutateAsync: updateMutate } = useUpdateAdmin(ADMIN_ID ? Number(ADMIN_ID) : 0);
    const { mutateAsync: updatePasswordBySuperAdmin } = useUpdatePasswordBySuperAdmin();
    const createParams = (updatedData: any) => {
        return {
            ...(updatedData.adminName && { admin_name: updatedData.adminName }),
            ...(updatedData.adminPhone && {
                admin_phone: updatedData.adminPhone,
            }),
            ...(updatedData.adminRank && { admin_rank: updatedData.adminRank }),
        };
    };
    const handleUpdate = async (updatedData: any) => {
        let params = createParams(updatedData);
        dispatch(startLoading());

        try {
            // 최고 관리자이고, 비밀번호를 수정하는 경우
            const { updatePassword } = updatedData;
            if (updatePassword !== "" && isSuperAdmin) {
                try {
                    await updatePasswordBySuperAdmin({
                        no: ADMIN_ID ? Number(ADMIN_ID) : 0,
                        passwdNew: updatePassword,
                    });
                } catch (error) {
                    setUpdatePasswordErrorMSG("다시 시도해주세요.");
                    return;
                }
            }

            try {
                await updateMutate(params);
                listRefetch();
                getRefetch();
                navigate("/admins/list");
            } catch (error: any) {
                setPageErrorMessage(error.toString());
            }
        } finally {
            dispatch(endLoading());
        }
    };

    return (
        <div css={adminUpdateWrap}>
            <AdminForm
                isUpdate={true}
                title={"관리자 수정"}
                initData={initAdmin}
                isSuperAdmin={isSuperAdmin}
                updatePasswordErrorMSG={updatePasswordErrorMSG}
                handleSubmit={(data: any) => {
                    setUpdatedAdmin({ ...data });
                    setPageErrorMessage("");
                }}
            />
            <div css={submitBtnDiv}>
                <p css={pageErrMsgBox}>{pageErrorMessage}</p>
                <Button
                    label={"수정"}
                    color={"gd"}
                    size={"medium"}
                    isDisabled={isDisabled}
                    handleClick={() => {
                        setIsModalShow(true);
                    }}
                    deleteBtn={false}
                    defaultValue={"등록"}
                />
            </div>
            <ConfirmModal
                children={"관리자 정보를 수정하시겠습니까?"}
                isModalShow={isModalShow}
                handleOk={() => {
                    handleUpdate(updatedAdmin);
                    setIsModalShow(false);
                }}
                handleCancel={() => {
                    setIsModalShow(false);
                }}
                buttonText={"수정"}
                disabled={false}
            />
        </div>
    );
}

const adminUpdateWrap = css`
    width: 100%;
    padding: 40px;
`;
const submitBtnDiv = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 20px;
`;
const pageErrMsgBox = css`
    color: ${Common.colors.error};
`;
export default AdminUpdate;
