import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Global } from "@emotion/react";
import { useSelector } from "react-redux";

import { storePathValue } from "./utils/store";

import Layout from "./layout";
import { LoadingBar, ErrorPopUp } from "./components";
import Login from "./pages/login/Login";
import MyPage from "./pages/myPage/MyPage";
import UserList from "./pages/user/UserList";
import ProductList from "./pages/product/ProductList";
import BannerList from "./pages/banner/BannerList";
import SMSList from "./pages/notify/SMSList";
import PushList from "./pages/notify/push/PushList";
import Logout from "./pages/login/Logout";
import TestPage from "./pages/TestPage";
import reset from "./styles/Reset";
import Home from "./pages/home/Home";
import UserDetail from "./pages/user/UserDetail";
import UserUpdate from "./pages/user/UserUpdate";
import UserViewsCount from "./pages/user/UserViewsCountList";
import UserLotViewersList from "./pages/user/UserLotViewersList";
import BannerCreate from "./pages/banner/BannerCreate";
import BannerUpdate from "./pages/banner/BannerUpdate";
import ProductAuctionDetail from "./pages/product/ProductAuctionDetail";
import ProductPublicDetail from "./pages/product/ProductPublicDetail";
import NewsList from "./pages/news/NewsList";
import NewsCreate from "./pages/news/NewsCreate";
import NewsUpdate from "./pages/news/NewsUpdate";
import PaymentList from "./pages/payment/PaymentList";
import PaymentSubscribe from "./pages/payment/PaymentSubscribe";
import PaymentExpert from "./pages/payment/PaymentExpert";
import RevenueList from "./pages/revenue/RevenueList";
import Support from "./pages/help/SupportList";
import FAQList from "./pages/help/FAQList";
import FAQForm from "./pages/help/FAQForm";
import NoticeList from "./pages/help/NoticeList";
import NoticeForm from "./pages/help/NoticeForm";
import ReplyWaiting from "./pages/help/containers/ReplyWaiting";
import ReplyDetail from "./pages/help/containers/ReplyDetail";
import PushCreate from "./pages/notify/push/PushCreate";
import PushDetail from "./pages/notify/push/PushDetail";
import PushUpdate from "./pages/notify/push/PushUpdate";
import TemplateList from "./pages/notify/template/TemplateList";
import TemplateCreate from "./pages/notify/template/TemplateCreate";
import TemplateUpdate from "./pages/notify/template/TemplateUpdate";
import SituationList from "./pages/notify/situation/SituationList";
import SituationCreate from "./pages/notify/situation/SituationCreate";
import SituationUpdate from "./pages/notify/situation/SituationUpdate";
import AdminList from "./pages/admin/AdminList";
import AdminDetail from "./pages/admin/AdminDetail";
import AdminCreate from "./pages/admin/AdminCreate";
import AdminUpdate from "./pages/admin/AdminUpdate";
import Monitor from "./pages/monitor/Monitor";
import CouponList from "./pages/coupon/CouponList";
import CouponCreate from "./pages/coupon/CouponCreate";
import CouponUpdate from "./pages/coupon/CouponUpdate";
import CouponDetail from "./pages/coupon/CouponDetail";
import CommodityMamagement from "./pages/commodity/CommodityManagement";
import CommodityProvision from "./pages/commodity/CommodityProvision";
import CommodityCreate from "./pages/commodity/CommodityCreate";
import CommodityUpdate from "./pages/commodity/CommodityUpdate";
import CommodityCopy from "./pages/commodity/CommodityCopy";
import ProvisionCreate from "./pages/commodity/provision/ProvisionCreate";
import ProvisionUpdate from "./pages/commodity/provision/ProvisionUpdate";
import ProvisionView from "./pages/commodity/provision/ProvisionView";
import DeungiList from "./pages/deungi/DeungiList";
import DeungiNotice from "./pages/deungi/deungiNotice/Index";
import DeungiNoticeList from "./pages/deungi/deungiNotice/DeungiNoticeList";
import DeungiNoticeForm from "./pages/deungi/deungiNotice/DeungiNoticeForm";
import CampusHome from "./pages/campus/Index";
import CampusList from "./pages/campus/CampusList";
import Campus from "./pages/campus/Campus";
import CampusBasicForm from "./pages/campus/CampusBasicForm";
import Partner from "./pages/Partner/Partner";
import PartnerRegistration from "./pages/Partner/PartnerRegistration";
import PartnerDetail from "./pages/Partner/PartnerDetail";
import PartnerBenefits from "./pages/Partner/Benefits/PartnerBenefits";
import PartnerBenefitsRegister from "./pages/Partner/Benefits/PartnerBenefitsRegister";
import ExpertHome from "./pages/expert/ExpertHome";
import ExpertForm from "./pages/expert/ExpertForm";
import ExpertUserList from "./pages/expert/ExpertUserList";
import ExpertInquiryList from "./pages/expert/ExpertInquiryList";
import ExpertAdvertisementList from "./pages/expert/Advertisement/ExpertAdvertisementList";
import ExpertUserDetail from "./pages/expert/ExpertUserDetail";
import DeungiChangeList from "./pages/deungiChange/DeungiChangeList";
import Settlement from "./pages/Partner/settlement";
import UserBannerClickCount from "./pages/user/UserBannerClickCount";
import ResetPasswordPage from "./pages/login/ResetPasswordPage";

function Router() {
    const isLoading = useSelector((state) => state.loading.isLoading);
    const isErrorPopUpShow = useSelector((state) => state.errorPopUp.isErrorPopUpShow);

    const location = useLocation();
    useEffect(() => {
        storePathValue();
    }, [location]);

    return (
        <>
            <Global styles={reset} />
            {isLoading && <LoadingBar />}
            {isErrorPopUpShow && <ErrorPopUp />}
            <Routes>
                <Route exact path="*" element={<Navigate replace to="/login" />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/resetPassword" element={<ResetPasswordPage />} />
                <Route exact path="/test" element={<TestPage />} />
                <Route path="/home" element={<Home />} />
                <Route path="/monitor" element={<Monitor />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/myPage" element={<Layout />}>
                    <Route path="list" element={<MyPage />} />
                </Route>
                <Route path="/business" element={<Layout />}>
                    <Route path="viewsCount" element={<UserViewsCount />} />
                    <Route path="lotViewsCount" element={<UserLotViewersList />} />
                    <Route path="bannerClickCount" element={<UserBannerClickCount />} />
                </Route>
                <Route path="/users" element={<Layout />}>
                    <Route path="list" element={<UserList />} />
                    <Route path="detail/:id" element={<UserDetail />} />
                    <Route path="update/:id" element={<UserUpdate />} />
                    <Route path="viewsCount" element={<UserViewsCount />} />
                    <Route path="lotViewsCount" element={<UserLotViewersList />} />
                </Route>
                <Route path="/experts" element={<Layout />}>
                    <Route path="home" element={<ExpertHome />} />
                    <Route path="register" element={<ExpertForm />} />
                    <Route path="update/:no" element={<ExpertForm />} />
                    <Route path="user/list" element={<ExpertUserList />} />
                    <Route path="user/detail/:no" element={<ExpertUserDetail />} />
                    <Route path="advertisement/list" element={<ExpertAdvertisementList />} />
                    <Route path="inquiry/list" element={<ExpertInquiryList />} />
                </Route>
                <Route path="/commodity" element={<Layout />}>
                    <Route path="list" element={<CommodityMamagement />} />
                    <Route path="provision" element={<CommodityProvision />} />
                    <Route path="create" element={<CommodityCreate />} />
                    <Route path="update/:id" element={<CommodityUpdate />} />
                    <Route path="copy/:id" element={<CommodityCopy />} />
                    <Route path="provision/create" element={<ProvisionCreate />} />
                    <Route path="provision/update/:id" element={<ProvisionUpdate />} />
                    <Route path="provision/view/:id" element={<ProvisionView />} />
                </Route>
                <Route path="/deungi" element={<Layout />}>
                    <Route path="list" element={<DeungiList />} />
                    <Route path="notice" element={<DeungiNotice />}>
                        <Route path="list" element={<DeungiNoticeList />} />
                        <Route path="create" element={<DeungiNoticeForm />} />
                        <Route path="update/:id" element={<DeungiNoticeForm />} />
                    </Route>
                </Route>
                <Route path="/payment" element={<Layout />}>
                    <Route path="list" element={<PaymentList />} />
                    <Route path="subscribe" element={<PaymentSubscribe />} />
                    <Route path="expert" element={<PaymentExpert />} />
                </Route>
                <Route path="/revenue" element={<Layout />}>
                    <Route path="list" element={<RevenueList />} />
                </Route>
                <Route path="/products" element={<Layout />}>
                    <Route path="list" element={<ProductList />} />
                    <Route path="auction/:id" element={<ProductAuctionDetail />} />
                    <Route path="public/:id" element={<ProductPublicDetail />} />
                </Route>
                <Route path="/coupon" element={<Layout />}>
                    <Route path="list" element={<CouponList />} />
                    <Route path="create" element={<CouponCreate />} />
                    <Route path="detail/:id" element={<CouponDetail />} />
                    <Route path="update/:id" element={<CouponUpdate />} />
                </Route>
                <Route path="/banners" element={<Layout />}>
                    <Route path="list" element={<BannerList />} />
                    <Route path="create" element={<BannerCreate />} />
                    <Route path="update/:id" element={<BannerUpdate />} />
                </Route>
                <Route path="/news" element={<Layout />}>
                    <Route path="list" element={<NewsList />} />
                    <Route path="create" element={<NewsCreate />} />
                    <Route path="update/:id" element={<NewsUpdate />} />
                </Route>
                <Route path="/notify" element={<Layout />}>
                    <Route path="sms" element={<SMSList />} />
                    <Route path="push" element={<PushList />} />
                    <Route path="push/create" element={<PushCreate />} />
                    <Route path="push/update/:id" element={<PushUpdate isUpdate={true} />} />
                    <Route path="push/detail/:id" element={<PushDetail />} />
                    <Route path="template" element={<TemplateList />} />
                    <Route path="template/create" element={<TemplateCreate />} />
                    <Route path="template/update/:id" element={<TemplateUpdate />} />
                    <Route path="template/detail/:id" element={<TemplateUpdate isDetail={true} />} />
                    <Route path="situation" element={<SituationList />} />
                    <Route path="situation/create" element={<SituationCreate />} />
                    <Route path="situation/update/:id" element={<SituationUpdate />} />
                    <Route path="situation/detail/:id" element={<SituationUpdate isDetail={true} />} />
                </Route>
                <Route path="/help" element={<Layout />}>
                    <Route path="support" element={<Support />} />
                    <Route path="support/reply/:id" element={<ReplyWaiting />} />
                    <Route path="support/detail/:id" element={<ReplyDetail />} />
                    <Route path="faq" element={<FAQList />} />
                    <Route path="faq/create" element={<FAQForm />} />
                    <Route path="faq/update/:id" element={<FAQForm isUpdate={true} />} />
                    <Route path="notice" element={<NoticeList />} />
                    <Route path="notice/create" element={<NoticeForm />} />
                    <Route path="notice/update/:id" element={<NoticeForm isUpdate={true} />} />
                </Route>
                <Route path="/admins" element={<Layout />}>
                    <Route path="list" element={<AdminList />} />
                    <Route path="detail/:id" element={<AdminDetail />} />
                    <Route path="create" element={<AdminCreate />} />
                    <Route path="update/:id" element={<AdminUpdate />} />
                </Route>
                <Route path="/campus" element={<Layout />}>
                    <Route path="management" element={<CampusHome />}>
                        <Route path="lectures" element={<CampusList />} />
                        <Route path="lecture/:id" element={<Campus />} />
                        <Route path="create" element={<CampusBasicForm />} />
                        <Route path="update/:id" element={<CampusBasicForm />} />
                    </Route>
                </Route>
                <Route path="/partner" element={<Layout />}>
                    <Route path="list" element={<Partner />} />
                    <Route path="register" element={<PartnerRegistration />} />
                    <Route path="detail/:no" element={<PartnerDetail />} />
                    <Route path="update/:no" element={<PartnerRegistration />} />
                    <Route path="benefits" element={<PartnerBenefits />} />
                    <Route path="benefits/register" element={<PartnerBenefitsRegister />} />
                    <Route path="settlement" element={<Settlement />} />
                </Route>
                <Route path="/deungiChange" element={<Layout />}>
                    <Route path="list" element={<DeungiChangeList />} />
                </Route>
            </Routes>
        </>
    );
}

export default Router;
